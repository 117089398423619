import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
// components
import { fileData } from '../../file-thumbnail';
// utils
import { fData } from '../../../utils/formatNumber';
export default function RejectionFiles({ fileRejections }) {
    if (!fileRejections.length) {
        return null;
    }
    return (_jsx(Paper, { variant: "outlined", sx: {
            py: 1,
            px: 2,
            mt: 3,
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
        }, children: fileRejections.map(({ file, errors }) => {
            const { path, size } = fileData(file);
            return (_jsxs(Box, { sx: { my: 1 }, children: [_jsxs(Typography, { variant: "subtitle2", noWrap: true, children: [path, " - ", size ? fData(size) : ''] }), errors.map((error) => (_jsxs(Box, { component: "span", sx: { typography: 'caption' }, children: ["- ", error.message] }, error.code)))] }, path));
        }) }));
}
